import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout/Layout';
import SEO from '../../components/SEO/Seo';
import Container from '../../components/Container/Container';
import Theme from '../../components/Theme/Theme';

export default function LegalTemplate({
    data, // this prop will be injected by the GraphQL query below.
}) {
    const { markdownRemark } = data; // data.markdownRemark holds your post data
    const { frontmatter, html } = markdownRemark;
    return (
        <Theme>
            <SEO title={frontmatter.title} />
            <Layout>
                <Container size="MD" className="legal">
                    <div dangerouslySetInnerHTML={{ __html: html }} />
                </Container>
            </Layout>
        </Theme>
    );
}

export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            html
            frontmatter {
                path
                title
            }
        }
    }
`;
